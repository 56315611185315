import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Route } from 'react-router-dom';
import { fetchData, formatDate } from './helpers';

import Dashboard from './components/pages/dashboard/DashboardContainer';
import Header from './components/header/HeaderContainer';
import Modal from './components/UI/Modal';

import CustomersContainer from './components/pages/customers/CustomersContainer';
import RatingsContainer from './components/pages/ratings/RatingsContainer';
import RequestsContainer from './components/pages/requests/RequestsContainer';
import SLAContainer from './components/pages/sla/SLAContainer';
import LabelsContainer from './components/pages/labels/LabelsContainer';
import WorklogsContainer from './components/pages/worklogs/WorklogsContainer';

const App = () => {
  const [user, setUser] = useState('');
  const [dates, setDates] = useState({ from: formatDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), to: formatDate(new Date().getTime()) });
  const [editing, setEditing] = useState(false);

  const getUserHandler = async () => {
    const data = await fetchData(`${process.env.REACT_APP_BACKEND_URL}/auth/user`);
    setUser(data.email)
  }

  const editDatesHandler = () => {
    setEditing(!editing)
  }

  const cancelHandler = () => {
    setEditing(!editing)
  }

  const acceptHandler = (event) => {
    event.preventDefault();
    setEditing(!editing)
    setDates({ from: formatDate(new Date(event.target.dateFrom.value)), to: formatDate(new Date(event.target.dateTo.value))})
  }

  useEffect(() => {  
    getUserHandler();
  }, [])

  return (
    <React.Fragment>
      <header>
        <Header user={user} />
        {editing &&
        createPortal(<Modal
          editDates={editDatesHandler}
          modalTitle="Sett tidsintervall"
          acceptButtonText="Søk"
          actionAccept={acceptHandler}
          cancelButtonText="Avbryt"
          actionCancel={cancelHandler}
        >
          <div>
            <label htmlFor="dateFrom">Fra og med</label>
            <input type="date" id="dateFrom" defaultValue={dates.from} />
          </div>
          <div>
            <label htmlFor="dateTo">Til og med</label>
            <input type="date" id="dateTo" defaultValue={dates.to} />
          </div>
        </Modal>, document.getElementById('modal'))}
      </header>
      {user &&
        <main>
          <Route path="/" exact>
            <Dashboard />
          </Route>
          <Route path="/customers" exact>
            <CustomersContainer editDates={editDatesHandler} dates={dates} />
          </Route>
          <Route path="/labels" exact>
            <LabelsContainer editDates={editDatesHandler} dates={dates} />
          </Route>
          <Route path="/ratings" exact>
            <RatingsContainer editDates={editDatesHandler} dates={dates} />
          </Route>
          <Route path="/requests" exact>
            <RequestsContainer editDates={editDatesHandler} dates={dates} />
          </Route>
          <Route path="/sla" exact>
            <SLAContainer editDates={editDatesHandler} dates={dates} />
          </Route>
          <Route path="/worklogs" exact>
            <WorklogsContainer editDates={editDatesHandler} dates={dates} />
          </Route>
        </main>
      }
    </React.Fragment>
  );
};

export default App;