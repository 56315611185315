import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import PageElement from '../../UI/PageElement';

const RatingsChart = props => {
	let chartData = [];
	const barColours = [
		'#050037',
		'#00ffb4',
		'#ff3246',
		'#000000',
		'#3c488f',
		'#646464',
		'#80ffda',
		'#ededed',
	];

	for (var rating in props.ratings) {
		chartData.push({
			name: props.ratings[rating].productName,
			data: Object.values(props.ratings[rating].ratings),
			color: barColours[rating],
		});
	}

	const options = {
		chart: {
			type: 'column',
			backgroundColor: 'rgba(0,0,0,0)',
		},
		title: {
			text: null,
		},
		xAxis: { categories: ['1', '2', '3', '4', '5'] },
		yAxis: {
			title: {
				text: 'Antall',
			},
		},
		series: chartData,
		tooltip: {
			shared: true,
		},
		credits: { enabled: false },
	};

	let data = [{ name: '1' }, { name: '2' }, { name: '3' }, { name: '4' }, { name: '5' }];

	for (var series in props.ratings) {
		for (var rating in props.ratings[series].ratings) {
			data[parseInt(rating) - 1][props.ratings[series].key] = props.ratings[series].ratings[rating];
		}
	}

	return (
		<section>
			<PageElement
				fullWidth
				padded
			>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</PageElement>
		</section>
	);
};

export default RatingsChart;
