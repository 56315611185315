import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { fetchData } from '../../../helpers';

import PageContainer from '../../UI/PageContainer';
import RatingsChart from './RatingsChart';
import RatingsTotals from './RatingsTotals';
import SetDates from '../../SetDates';

const RatingsContainer = props => {
	const [ratingsData, setRatingsData] = useState([]);
	const [ratingsAmount, setRatingsAmount] = useState(0);
	const [ratingsTotal, setRatingsTotal] = useState(0);

	useEffect(() => {
		const getWorklogsHandler = async () => {
			let total = 0;
			let amount = 0;
			const data = await fetchData(
				`${process.env.REACT_APP_BACKEND_URL}/api/ratings/detailed?from=${props.dates.from}&to=${props.dates.to}`
			);
			setRatingsData(data);
			for (var product in data) {
				for (var score in data[product].ratings) {
					amount += data[product].ratings[score];
					total += data[product].ratings[score] * parseInt(score);
				}
			}
			setRatingsAmount(amount);
			setRatingsTotal(total);
		};

		getWorklogsHandler();
	}, [props.dates]);

	return (
		<PageContainer background>
			{createPortal(
				<SetDates
					editDates={props.editDates}
					dates={props.dates}
				/>,
				document.getElementById('header-container_middle')
			)}
			<RatingsTotals
				amount={ratingsAmount}
				total={ratingsTotal}
			/>
			<RatingsChart ratings={ratingsData} />
		</PageContainer>
	);
};

export default RatingsContainer;
