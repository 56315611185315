import Card from './UI/Card';

const TableButtonRow = props => {
	return (
		<section className='tableButtonRow'>
			<Card>
				{props.array.map((v, i) => {
					if (i % 10 === 0) {
						if (props.array.length >= i + 10) {
							return (
								<button
									key={i}
									type='button'
									className={props.selected == [i, 10] ? '' : 'alternative'}
									value={[i, 10]}
									onClick={props.clickHandler}
								>
									{i + 1} - {i + 10}
								</button>
							);
						} else {
							return (
								<button
									key={i}
									type='button'
									className={
										props.selected == [i, props.array.length - i]
											? ''
											: 'alternative'
									}
									value={[i, props.array.length - i]}
									onClick={props.clickHandler}
								>
									{i + 1} - {props.array.length}
								</button>
							);
						}
					}
				})}
			</Card>
		</section>
	);
};

export default TableButtonRow;
