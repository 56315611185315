import Card from './UI/Card';

import styles from './SetDates.module.css';

const SetDates = (props) => {
  return (
    <section>
      <Card>
        <div className={styles.container}>
          <div className={styles.element}>
            <label htmlFor="fromDate">Fra og med</label>
            <input readOnly id="fromDate" type="date" value={props.dates.from} />
          </div>
          <div className={styles.element}>
            <label htmlFor="toDate">Til og med</label>
            <input readOnly id="toDate" type="date" value={props.dates.to} />
          </div>
          <button onClick={props.editDates}>Endre</button>
        </div>
      </Card>
    </section>
  )
}

export default SetDates