import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { fetchData } from '../../../helpers';

import CustomersFilters from './CustomersFilters';
import CustomersTable from './CustomersTable';
import PageContainer from '../../UI/PageContainer';
import SetDates from '../../SetDates';
import TableButtonRow from '../../TableButtonRow';

const CustomersContainer = props => {
	const [customersData, setCustomersData] = useState([]);
	const [visibleCustomers, setVisibleCustomers] = useState([0, 10]);
	const [representedProducts, setRepresentedProducts] = useState([]);
	const [filteredCustomersData, setFilteredCustomersData] = useState([]);

	const getWorklogsHandler = async () => {
		let productsArray = [];
		const data = await fetchData(
			`${process.env.REACT_APP_BACKEND_URL}/api/customers/support/detailed?from=${props.dates.from}&to=${props.dates.to}`
		);
		for (var customer in data) {
			for (var product in data[customer].products) {
				productsArray.push(
					`${data[customer].products[product].id}-${data[customer].products[product].productName}`
				);
			}
		}
		setRepresentedProducts([...new Set(productsArray)]);
		setCustomersData(data);
		setFilteredCustomersData(data);
	};

	const tableButtonsHandler = event => {
		const newValues = event.target.attributes.value.value.split(',');
		setVisibleCustomers([parseInt(newValues[0]), parseInt(newValues[1])]);
	};

	const onChangeHandler = e => {
		if (e.target.value === '') {
			setFilteredCustomersData(customersData);
		} else {
			let tempCustomersDataArray = [];
			for (var customer in customersData) {
				let tempProductsObject = customersData[customer].products.filter(
					product => product.id === e.target.value
				);
				if (tempProductsObject.length > 0) {
					tempCustomersDataArray.push({ ...customersData[customer], products: tempProductsObject });
				}
			}
			setFilteredCustomersData(tempCustomersDataArray);
		}
	};

	useEffect(() => {
		getWorklogsHandler();
	}, [props.dates]);

	return (
		<PageContainer background>
			{createPortal(
				<SetDates
					editDates={props.editDates}
					dates={props.dates}
				/>,
				document.getElementById('header-container_middle')
			)}
			<CustomersFilters
				onChange={onChangeHandler}
				products={representedProducts}
			/>
			<CustomersTable
				customers={filteredCustomersData.filter(
					(v, i) => i >= visibleCustomers[0] && i < visibleCustomers[0] + visibleCustomers[1]
				)}
			/>
			{filteredCustomersData.length > 10 && (
				<TableButtonRow
					array={filteredCustomersData}
					clickHandler={tableButtonsHandler}
					selected={visibleCustomers.toString()}
				/>
			)}
		</PageContainer>
	);
};

export default CustomersContainer;
