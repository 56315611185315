import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const RequestsChart = props => {
	const options = {
		chart: {
			type: 'pie',
			backgroundColor: 'rgba(0,0,0,0)',
			height: 250,
		},
		title: { text: null },

		series: [
			{
				name: 'Antall',
				colorByPoint: true,
				data: [
					{
						name: 'Support',
						y: props.issues.support,
						color: '#050037',
					},
					{
						name: 'Bestillinger',
						y: props.issues.order,
						color: '#00ffb4',
					},
					{
						name: 'Feil',
						y: props.issues.bug,
						color: '#ff3246',
					},
					{
						name: 'Forslag',
						y: props.issues.suggestion,
						color: '#000000',
					},
				],
			},
		],
		credits: {
			enabled: false,
		},
	};
	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={options}
		/>
	);
};

export default RequestsChart;
