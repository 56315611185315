import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { fetchData } from '../../../helpers';

import PageContainer from '../../UI/PageContainer';
import SetDates from '../../SetDates';
import SLACharts from './SLACharts';
import SLAFilters from './SLAFilters';

const SLAContainer = props => {
	const [slaData, setSLAData] = useState([]);
	const [representedSLAs, setRepresentedSLAs] = useState([]);
	const [filteredSLAData, setFilteredSLAData] = useState([]);

	const getSLAHandler = async () => {
		let slaArray = [];
		const data = await fetchData(
			`${process.env.REACT_APP_BACKEND_URL}/api/sla/support/detailed?from=${props.dates.from}&to=${props.dates.to}`
		);
		for (var product in data) {
			product !== 'total' && slaArray.push(`${data[product].id}-${data[product].productName}`);
		}
		setRepresentedSLAs([...new Set(slaArray)]);
		setSLAData(data);
		setFilteredSLAData(data);
	};

	const onChangeHandler = e => {
		if (e.target.value === '') {
			setFilteredSLAData(slaData);
		} else {
			setFilteredSLAData(slaData.filter(product => product.id === e.target.value));
		}
	};

	useEffect(() => {
		getSLAHandler();
	}, [props.dates]);

	return (
		<PageContainer background>
			{createPortal(
				<SetDates
					editDates={props.editDates}
					dates={props.dates}
				/>,
				document.getElementById('header-container_middle')
			)}
			<SLAFilters
				onChange={onChangeHandler}
				products={representedSLAs}
			/>
			<SLACharts sla={filteredSLAData} />
		</PageContainer>
	);
};

export default SLAContainer;
