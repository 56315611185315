import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { fetchData } from '../../helpers';

import './HeaderContainer.css';

const Header = (props) => {
  const [version, setVersion] = useState('');
    
  const getVersionHandler = async () => {
    const data = await fetchData(`${process.env.REACT_APP_BACKEND_URL}/api/version`);
    setVersion(data.version)
  }

  useEffect(() => {  
    getVersionHandler();
  }, [])

  return (
    <div className="header-container">
      <div className="header-container_top">
        <div className="header-container_title">
          <h1>KF JIRA Reports</h1>
          <p>v{version}</p>
        </div>
        <div className="header-container_user">
          <div className="header-container_user-email">{props.user ? props.user : <a href="/auth/atlassian">Logg inn</a>}</div>
        </div>
      </div>
      <div className="header-container_middle" id="header-container_middle"></div>
      {props.user &&
      <div className="header-container_bottom">
        <nav className="header-container_menu">
          <li>
            <NavLink to="/" exact>Dashboard</NavLink>
          </li>
          <li>
            <NavLink to="/requests" exact>Henvendelser</NavLink>
          </li>
          <li>
            <NavLink to="/customers" exact>Kunder</NavLink>
          </li>
          <li>
            <NavLink to="/ratings" exact>Tilfredshet</NavLink>
          </li>
          <li>
            <NavLink to="/sla" exact>SLA</NavLink>
          </li>
          <li>
            <NavLink to="/labels" exact>Merkelapper</NavLink>
          </li>
          <li>
            <NavLink to="/worklogs" exact>Timer</NavLink>
          </li>
        </nav>
      </div>
      }
    </div>
  )
}

export default Header