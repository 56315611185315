import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const SLAChart = props => {
	const options = {
		chart: {
			type: 'pie',
			backgroundColor: 'rgba(0,0,0,0)',
			height: 300,
		},
		title: { text: null },

		tooltip: {
			shared: true,
		},

		series: [
			{
				name: 'Antall',
				colorByPoint: true,
				data: [
					{
						name: 'Met',
						y: props.met,
						color: '#119911',
					},
					{
						name: 'Breached',
						y: props.breached,
						color: '#CC2222',
					},
				],
			},
		],
		credits: {
			enabled: false,
		},
	};
	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={options}
		/>
	);
};

export default SLAChart;
