import styles from './Card.module.css';

const Card = (props) => {
  return (
    <div
      className={`${styles.card} ${props.className} ${props.fullWidth ? styles.fullWidth : ''} ${props.halfWidth ? styles.halfWidth : ''}`}
    >
      {props.children}
    </div>
  );
} 

export default Card;