import Card from './Card'

import styles from './Modal.module.css';

const Modal = (props) => {

  return (
    <div>
      <div className={styles.backdrop} />
      <Card className={styles.modal}>
        <form onSubmit={props.actionAccept}>
          <header className={styles.header}>
            <h2>{props.modalTitle}</h2>
          </header>
          <div className={styles.content}>
            {props.children}
          </div>
          <footer className={styles.actions}>
            <button type="button" className="alternative" onClick={props.actionCancel}>{props.cancelButtonText}</button>
            <button type="submit">{props.acceptButtonText}</button>
          </footer>
        </form>
      </Card>
    </div>
  )
}

export default Modal;