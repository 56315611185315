import PageElement from '../../UI/PageElement';
import SLAChart from './SLAChart';

const SLACharts = props => {
	let slaFirstResponse = 0;
	let slaResolution = 0;

	let dataFirstResponse = [
		{ name: 'Met', value: 0 },
		{ name: 'Breached', value: 0 },
	];
	let dataResolution = [
		{ name: 'Met', value: 0 },
		{ name: 'Breached', value: 0 },
	];

	for (var product in props.sla) {
		if (product !== 'total') {
			dataFirstResponse[0].value += props.sla[product].sla.firstResponse.met;
			dataFirstResponse[1].value += props.sla[product].sla.firstResponse.breached;
			dataResolution[0].value += props.sla[product].sla.resolution.met;
			dataResolution[1].value += props.sla[product].sla.resolution.breached;
		}

		if (dataFirstResponse[0].value + dataFirstResponse[1].value === 0) {
			slaFirstResponse = ' - ';
		} else {
			slaFirstResponse =
				(
					(dataFirstResponse[0].value / (dataFirstResponse[0].value + dataFirstResponse[1].value)) *
					100
				).toFixed(process.env.REACT_APP_DECIMALS) + ' %';
		}

		if (dataResolution[0].value + dataResolution[1].value === 0) {
			slaResolution = ' - ';
		} else {
			slaResolution =
				(
					(dataResolution[0].value / (dataResolution[0].value + dataResolution[1].value)) *
					100
				).toFixed(process.env.REACT_APP_DECIMALS) + ' %';
		}
	}

	return (
		<section>
			<PageElement
				halfWidth
				padded
			>
				<h2>{`First Response (${slaFirstResponse})`}</h2>
				<hr></hr>
				<SLAChart
					met={dataFirstResponse[0].value}
					breached={dataFirstResponse[1].value}
				/>
			</PageElement>
			<PageElement
				halfWidth
				padded
			>
				<h2>{`Resolution (${slaResolution})`}</h2>
				<hr></hr>
				<SLAChart
					met={dataResolution[0].value}
					breached={dataResolution[1].value}
				/>
			</PageElement>
		</section>
	);
};

export default SLACharts;
