import PageElement from '../../UI/PageElement';
import RequestsChart from './RequestsChart';

const RequestsCharts = props => {
	console.log(props);

	return (
		<section>
			<PageElement
				halfWidth
				padded
			>
				<h2>Nye</h2>
				<hr></hr>
				<RequestsChart issues={props.created} />
			</PageElement>
			<PageElement
				halfWidth
				padded
			>
				<h2>Løst</h2>
				<hr></hr>
				<RequestsChart issues={props.resolved} />
			</PageElement>
		</section>
	);
};

export default RequestsCharts;
