import PageElement from "../../UI/PageElement"

const LabelsTable = (props) => {
  return (
    <section>
      <PageElement fullWidth>
        <table>
          <thead>
            <tr>
              <th>Merkelapp</th>
              <th>Antall</th>
              <th>Saker</th>
            </tr>
          </thead>
          <tbody>
          {props.labels.map(labelsRow => (
            <tr key={labelsRow.label}>
              <td>{labelsRow.label}</td>
              <td>{labelsRow.issues.length}</td>
              <td>{labelsRow.issues.map(issue => (<a key={issue} href={`https://kf-no.atlassian.net/browse/${issue}`}>{issue}</a>))}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </PageElement>
    </section>
  )
}

export default LabelsTable