import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { fetchData } from '../../../helpers';

import PageContainer from '../../UI/PageContainer';
import RequestsCharts from './RequestsCharts';
import RequestsTable from './RequestsTable';
import SetDates from '../../SetDates';
import TableButtonRow from '../../TableButtonRow';

const RequestsContainer = props => {
	const [requestsData, setrequestsData] = useState([]);
	const [visibleRequests, setVisibleRequests] = useState([0, 10]);
	const [selectedRequests, setSelectedRequests] = useState('');

	const getRequestsHandler = async () => {
		const data = await fetchData(
			`${process.env.REACT_APP_BACKEND_URL}/api/issues/created/support/detailed?from=${props.dates.from}&to=${props.dates.to}`
		);
		setrequestsData(data);
	};

	const selectRowHandler = key => {
		setSelectedRequests(requestsData.filter(v => v.id == key)[0]);
	};

	const tableButtonsHandler = event => {
		const newValues = event.target.attributes.value.value.split(',');
		setVisibleRequests([parseInt(newValues[0]), parseInt(newValues[1])]);
	};

	useEffect(() => {
		getRequestsHandler();
	}, [props.dates]);

	return (
		<PageContainer background>
			{createPortal(
				<SetDates
					editDates={props.editDates}
					dates={props.dates}
				/>,
				document.getElementById('header-container_middle')
			)}
			<RequestsTable
				requests={requestsData.filter(
					(v, i) => i >= visibleRequests[0] && i < visibleRequests[0] + visibleRequests[1]
				)}
				totalCreated={requestsData.reduce((accumulator, request) => {
					return (
						accumulator +
						request.created.issueTypes.support +
						request.created.issueTypes.order +
						request.created.issueTypes.bug +
						request.created.issueTypes.suggestion
					);
				}, 0)}
				totalResolved={requestsData.reduce((accumulator, request) => {
					return (
						accumulator +
						request.resolved.issueTypes.support +
						request.resolved.issueTypes.order +
						request.resolved.issueTypes.bug +
						request.resolved.issueTypes.suggestion
					);
				}, 0)}
				selectRow={selectRowHandler}
			/>
			{requestsData.length > 10 && (
				<TableButtonRow
					array={requestsData}
					clickHandler={tableButtonsHandler}
					selected={visibleRequests.toString()}
				/>
			)}
			{selectedRequests != '' && (
				<RequestsCharts
					created={selectedRequests.created.issueTypes}
					resolved={selectedRequests.resolved.issueTypes}
				/>
			)}
		</PageContainer>
	);
};

export default RequestsContainer;
