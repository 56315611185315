import PageElement from '../../UI/PageElement';

const CustomersTable = props => {
	return (
		<section>
			<PageElement fullWidth>
				<table>
					<thead>
						<tr>
							<th>Kunde</th>
							<th>Antall henvendelser</th>
							<th>%</th>
						</tr>
					</thead>
					<tbody>
						{props.customers.map(customersRow => (
							<tr key={customersRow.id}>
								<td>{customersRow.customerName}</td>
								<td>
									{customersRow.products.reduce((a, v) => {
										return a + v.issues.length;
									}, 0)}
								</td>
								<td>{customersRow.percentage.toFixed(process.env.REACT_APP_DECIMALS)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</PageElement>
		</section>
	);
};

export default CustomersTable;
