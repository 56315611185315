import PageElement from '../../UI/PageElement';

const RatingsTotals = props => {
	return (
		<section>
			<PageElement halfWidth>
				<div className={'halfColumn'}>
					<label>
						<strong>Total antall: </strong>
					</label>
					<label>{props.amount}</label>
				</div>
				<div className={'halfColumn'}>
					<label>
						<strong>Totalt snitt: </strong>
					</label>
					<label>
						{props.amount > 0
							? (props.total / props.amount).toFixed(process.env.REACT_APP_DECIMALS)
							: 0}
					</label>
				</div>
			</PageElement>
		</section>
	);
};

export default RatingsTotals;
