import { useCSVDownloader } from 'react-papaparse';
import { formatTime } from '../../../helpers';

import PageElement from '../../UI/PageElement';

const WorklogsTotals = props => {
	const { CSVDownloader, Type } = useCSVDownloader();
	let csvData = [];

	for (var customer in props.worklogs) {
		for (var issue in props.worklogs[customer].issues) {
			for (var worklog in props.worklogs[customer].issues[issue].worklogs) {
				const date = new Date(props.worklogs[customer].issues[issue].worklogs[worklog].date);
				csvData.push({
					Kunde: props.worklogs[customer].customerName,
					Produkt: props.worklogs[customer].productName,
					Sak: props.worklogs[customer].issues[issue].key,
					Sammendrag: props.worklogs[customer].issues[issue].summary,
					Dato: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
					Agent: props.worklogs[customer].issues[issue].worklogs[worklog].agent,
					Beskrivelse: props.worklogs[customer].issues[issue].worklogs[worklog].text,
					Tid: formatTime(props.worklogs[customer].issues[issue].worklogs[worklog].amount),
				});
			}
		}
	}
	return (
		<section>
			<PageElement halfWidth>
				<CSVDownloader
					type={Type.Button}
					filename={`worklogs_${props.dates.from.replaceAll('-', '')}_${props.dates.to.replaceAll(
						'-',
						''
					)}`}
					bom={true}
					config={{
						delimiter: ';',
					}}
					data={csvData}
				>
					Last ned CSV
				</CSVDownloader>
			</PageElement>
		</section>
	);
};

export default WorklogsTotals;
