import PageElement from '../../UI/PageElement';

const SLAFilters = props => {
	return (
		<section>
			<PageElement halfWidth>
				<label>Filtrer på valgt produkt:</label>
				<select onChange={props.onChange}>
					<option></option>
					{props.products.map(product => (
						<option
							key={product.split('-')[0]}
							value={product.split('-')[0]}
						>
							{product.split('-')[1]}
						</option>
					))}
				</select>
			</PageElement>
		</section>
	);
};

export default SLAFilters;
