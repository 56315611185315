import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { fetchData } from '../../../helpers';

import LabelsTable from './LabelsTable';
import SetDates from '../../SetDates';
import TableButtonRow from '../../TableButtonRow';
import PageContainer from '../../UI/PageContainer';

const LabelsContainer = (props) => {
  const [labelsData, setLabelsData] = useState([]);
  const [visibleLabels, setVisibleLabels] = useState([0, 10]);

  const getLabelsHandler = async () => {
    const data = await fetchData(`${process.env.REACT_APP_BACKEND_URL}/api/labels?from=${props.dates.from}&to=${props.dates.to}`);
    setLabelsData(data)
  }

  useEffect(() => {  
    getLabelsHandler();
  }, [props.dates])

  const tableButtonsHandler = (event) => {
    const newValues = event.target.attributes.value.value.split(',')
    setVisibleLabels([parseInt(newValues[0]), parseInt(newValues[1])]);
  }

  return (
    <PageContainer background>
      {createPortal(<SetDates editDates={props.editDates} dates={props.dates} />, document.getElementById('header-container_middle'))}
      <LabelsTable labels={labelsData.filter((v, i) => (i >= visibleLabels[0] && i < (visibleLabels[0]+visibleLabels[1])))} />
      {labelsData.length > 10 && <TableButtonRow array={labelsData} clickHandler={tableButtonsHandler} selected={visibleLabels.toString()}/>}
    </PageContainer>
  )
}

export default LabelsContainer;