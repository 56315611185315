import { useEffect, useState } from 'react';
import { fetchData } from '../../../helpers';

import DashboardItem from './DashboardItem';
import PageContainer from '../../UI/PageContainer';

const Dashboard = () => {
	const [requestsData, setRequestsData] = useState({
		id: 1,
		month: '-',
		title: 'Henvendelser',
		week: '-',
	});
	const [customersData, setCustomersData] = useState({
		id: 2,
		month: '-',
		title: 'Kunder',
		week: '-',
	});
	const [ratingsData, setRatingsData] = useState({
		id: 3,
		month: '-',
		title: 'Tilfredshet',
		week: '-',
	});
	const [slaData, setSlaData] = useState({ id: 4, month: '-', title: 'SLA', week: '-' });

	const getCustomersHandler = async () => {
		const data = await fetchData(
			`${process.env.REACT_APP_BACKEND_URL}/api/customers/support/dashboard`
		);
		setCustomersData({
			...customersData,
			month: data.month,
			week: data.week,
		});
	};
	const getRequestsHandler = async () => {
		const data = await fetchData(
			`${process.env.REACT_APP_BACKEND_URL}/api/issues/created/support/dashboard`
		);
		setRequestsData({
			...requestsData,
			month: data.month,
			week: data.week,
		});
	};
	const getRatingsHandler = async () => {
		const data = await fetchData(`${process.env.REACT_APP_BACKEND_URL}/api/ratings/dashboard`);
		setRatingsData({
			...ratingsData,
			month: data.month.toFixed(process.env.REACT_APP_DECIMALS),
			week: data.week.toFixed(process.env.REACT_APP_DECIMALS),
		});
	};

	const getSLAHandler = async () => {
		const data = await fetchData(`${process.env.REACT_APP_BACKEND_URL}/api/sla/support/dashboard`);
		setSlaData({
			...slaData,
			month: data.month.sla.toFixed(process.env.REACT_APP_DECIMALS),
			week: data.week.sla.toFixed(process.env.REACT_APP_DECIMALS),
		});
	};

	useEffect(() => {
		getCustomersHandler();
		getRatingsHandler();
		getRequestsHandler();
		getSLAHandler();
	}, []);

	return (
		<PageContainer>
			<section>
				<DashboardItem
					key={requestsData.id}
					month={requestsData.month}
					title={requestsData.title}
					week={requestsData.week}
				/>
				<DashboardItem
					key={customersData.id}
					month={customersData.month}
					title={customersData.title}
					week={customersData.week}
				/>
				<DashboardItem
					key={ratingsData.id}
					month={ratingsData.month}
					title={ratingsData.title}
					week={ratingsData.week}
				/>
				<DashboardItem
					key={slaData.id}
					month={slaData.month}
					title={slaData.title}
					week={slaData.week}
				/>
			</section>
		</PageContainer>
	);
};

export default Dashboard;
