import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { fetchData } from '../../../helpers';

import PageContainer from '../../UI/PageContainer';
import SetDates from '../../SetDates';
import TableButtonRow from '../../TableButtonRow';
import WorklogsTable from './WorklogsTable';
import WorklogsTotals from './WorklogsTotals';

const RequestsContainer = props => {
	const [worklogsData, setWorklogsData] = useState([]);
	const [visibleWorklogs, setVisibleWorklogs] = useState([0, 10]);

	const getWorklogsHandler = async () => {
		const data = await fetchData(
			`${process.env.REACT_APP_BACKEND_URL}/api/worklogs?from=${props.dates.from}&to=${props.dates.to}`
		);
		setWorklogsData(data);
		console.log(data);
	};

	useEffect(() => {
		getWorklogsHandler();
	}, [props.dates]);

	const tableButtonsHandler = event => {
		const newValues = event.target.attributes.value.value.split(',');
		setVisibleWorklogs([parseInt(newValues[0]), parseInt(newValues[1])]);
	};

	return (
		<PageContainer background>
			{createPortal(
				<SetDates
					editDates={props.editDates}
					dates={props.dates}
				/>,
				document.getElementById('header-container_middle')
			)}
			<WorklogsTotals
				dates={props.dates}
				worklogs={worklogsData}
			/>
			<WorklogsTable
				worklogs={worklogsData.filter(
					(v, i) => i >= visibleWorklogs[0] && i < visibleWorklogs[0] + visibleWorklogs[1]
				)}
			/>
			{worklogsData.length > 10 && (
				<TableButtonRow
					array={worklogsData}
					clickHandler={tableButtonsHandler}
					selected={visibleWorklogs.toString()}
				/>
			)}
		</PageContainer>
	);
};

export default RequestsContainer;
