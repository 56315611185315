export const fetchData = async url => {
	const response = await fetch(url);
	const data = await response.json();
	return data;
};

export const formatDate = d => {
	const date = new Date(d);
	return `${date.getFullYear()}-${('00' + (date.getMonth() + 1)).slice(-2)}-${(
		'00' + date.getDate()
	).slice(-2)}`;
};

export const formatTime = time => {
	let formattedTime = '';
	time >= 3600 && (formattedTime += Math.floor(time / 3600) + 't');
	time >= 3600 && time % 3600 !== 0 && (formattedTime += ' ');
	time % 3600 !== 0 && (formattedTime += Math.floor(time % 3600) / 60 + 'm');
	return formattedTime;
};

export const uniqueArray = (value, index, self) => {
	return self.indexOf(value) === index;
};
