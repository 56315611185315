// import { Fragment } from 'react';
import PageElement from '../../UI/PageElement';
import { formatTime } from '../../../helpers';

const WorklogsTable = props => {
	return (
		<section>
			<PageElement fullWidth>
				<table>
					<thead>
						<tr>
							<th>Kunde</th>
							<th>Produkt</th>
							<th>Timer</th>
							<th>Saker</th>
						</tr>
					</thead>
					<tbody>
						{props.worklogs.map((worklogsRow, i) => (
							<tr key={i}>
								<td>{worklogsRow.customerName}</td>
								<td>{worklogsRow.productName}</td>
								<td>{formatTime(worklogsRow.time)}</td>
								<td>
									{worklogsRow.issues.map(issue => (
										<span key={issue.key}>
											<a href={`https://kf-no.atlassian.net/browse/${issue.key}`}>{issue.key}</a>{' '}
											<span>({formatTime(issue.worklogs.reduce((a, c) => a + c.amount, 0))})</span>
										</span>
									))}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</PageElement>
		</section>
	);
};

export default WorklogsTable;
