import Card from '../../UI/Card';

import styles from './DashboardItem.module.css';

const DashboardItem = (props) => {
  return (
    <Card>
      <div className={styles.container}>
        <h2>{props.title}</h2>
        <hr></hr>
        <div className={styles.stats}>
          <div>
            <h3>{props.week}</h3>
            <p>Siste 7 dager</p>
          </div>
          <div>
            <h3>{props.month}</h3>
            <p>Siste 30 dager</p>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default DashboardItem;