import PageElement from '../../UI/PageElement';

const RequestsTable = props => {
	return (
		<section>
			<PageElement fullWidth>
				<table>
					<thead>
						<tr>
							<th></th>
							<td colSpan='3'>Nye</td>
							<td colSpan='3'>Løst</td>
						</tr>
						<tr>
							<th>Produkt</th>
							<th>Antall</th>
							{process.env.REACT_APP_NETWORKS_ENABLED === 'true' && <th>Nettverk</th>}
							<th>Totalt</th>
							<th>Antall</th>
							{process.env.REACT_APP_NETWORKS_ENABLED === 'true' && <th>Nettverk</th>}
							<th>Totalt</th>
						</tr>
					</thead>
					<tbody>
						{props.requests.map(requestsRow => (
							<tr
								key={requestsRow.id}
								onClick={() => props.selectRow(requestsRow.id)}
							>
								<td>{requestsRow.productName}</td>
								<td>
									{requestsRow.created.issueTypes.support +
										requestsRow.created.issueTypes.order +
										requestsRow.created.issueTypes.bug +
										requestsRow.created.issueTypes.suggestion}
								</td>
								{process.env.REACT_APP_NETWORKS_ENABLED === 'true' && (
									<td>
										{typeof requestsRow.created.share.network === 'number'
											? requestsRow.created.share.network.toFixed(process.env.REACT_APP_DECIMALS)
											: requestsRow.created.share.network}
									</td>
								)}
								<td>
									{typeof requestsRow.created.share.total === 'number'
										? requestsRow.created.share.total.toFixed(process.env.REACT_APP_DECIMALS)
										: requestsRow.created.share.total}
								</td>
								<td>
									{requestsRow.resolved.issueTypes.support +
										requestsRow.resolved.issueTypes.order +
										requestsRow.resolved.issueTypes.bug +
										requestsRow.resolved.issueTypes.suggestion}
								</td>
								{process.env.REACT_APP_NETWORKS_ENABLED === 'true' && (
									<td>
										{typeof requestsRow.resolved.share.network === 'number'
											? requestsRow.resolved.share.network.toFixed(process.env.REACT_APP_DECIMALS)
											: requestsRow.resolved.share.network}
									</td>
								)}
								<td>
									{typeof requestsRow.resolved.share.total === 'number'
										? requestsRow.resolved.share.total.toFixed(process.env.REACT_APP_DECIMALS)
										: requestsRow.resolved.share.total}
								</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td>Totalt</td>
							<td colSpan={process.env.REACT_APP_NETWORKS_ENABLED === 'true' ? '3' : '2'}>
								{props.totalCreated}
							</td>
							<td colSpan={process.env.REACT_APP_NETWORKS_ENABLED === 'true' ? '3' : '2'}>
								{props.totalResolved}
							</td>
						</tr>
					</tfoot>
				</table>
			</PageElement>
		</section>
	);
};

export default RequestsTable;
